/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fontLibrary/Roboto.woff');
  font-weight: 500;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  vertical-align: middle;
  font-size: 14px;
  vertical-align: middle;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto","Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}

html {
	background-color: rgba(239, 246, 255, 100);
}

body {
  line-height: 1;
}

* {
	box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// input::-webkit-input-placeholder { /* Edge */
//   color: var(--adm-color-grey) !important;
// }
// input::placeholder {
//   color: var(--adm-color-grey) !important;
// }
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
a{
  text-decoration: none;
}
