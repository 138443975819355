.Introduce{
  background-color: #F7F8FA;
  min-height: calc(100vh - 61px);
  display: flex;
  flex-direction: column;
  .HeaderPage{
      background-color: #fff;
  }
  .container{
    min-width: 1340px;
    height: 95%;
    justify-content: center;
    align-items: flex-start;
    // align-items: center;
    flex: 1;
    .Home{
      display: flex;
      margin-top: 20px;
      height: 96%;
      padding: 20px 20px;
      width: 100%;
      background-color: #fff;
      flex-direction: column;
      .Introduce_left {
        .Memory{
          display: flex;
          justify-content: space-between;
          p{
            margin-bottom: 0px;
          }
        }
        .space{
          color: rgba(93, 111, 143, 1);
          font-size: 16px;
        }
        .illustrate{
          color: rgba(93, 111, 143, 1);
          font-size: 16px;
        }
        table{
          width: 100%;
          border: 1px solid #F4F4F5;
          margin-bottom: 40px;
          th{
            padding: 0px 4px;
            height: 48px;
            line-height: 20px;
            background-color: rgba(244, 244, 245, 1);
            text-align: left;
          }
          td{
            padding: 0px 4px;
            height: 48px;
            line-height: 20px;
            border-bottom: 1px solid #F4F4F5;
            font-weight: 400;
            &:nth-of-type(1){
              font-weight: 600;
            }
          }
        }
        .handle{
          span{
            font-weight: 600;
            color: #000;
            margin-bottom: 12px;
            display: inline-block;
          }
          p{
            margin-bottom: 0px;
          }
        }
      }
      .ant-progress-inner{
        border-radius: 2px !important;
        .ant-progress-success-bg, .ant-progress-bg{
          height: 15px !important;
          border-radius: 2px !important;
          background-color: #AECBFF ;
        }
      }
      .ant-progress-line{
        width: 100% !important;
        margin-bottom: 10px;
      }
      .ant-progress {
        .ant-progress-outer{
          min-width: 100%;
        }
      }
      .Introduce_Right{
        display: flex;
        justify-content: space-between;
        p{
          color: rgba(93, 111, 143, 1);
          font-size: 16px;
        }
        .free{
          background-image: url('../../assets/images/free.png');
          background-size: 100% 100%;
          padding: 30px;
          margin-bottom: 0px;
          display: flex;
          flex-direction: column;
          height: 100%;
          cursor: pointer;

          .span{
            color: #fff;
            font-size: 28px;
            text-align: center;
          }
          p{
            color: #fff;
            font-size: 14px;
            text-align: center;
          }
          button{
            background: linear-gradient(93.02deg, rgba(255,152,37,1) -2.03%,rgba(222,134,143,1) 98.94%);
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            border: 0px;
            border-radius: 35px;
          }
        }
        .freer{
          background-image: url('../../assets/images/freer.png');
          background-size: 100% 100%;
          padding: 30px;
          margin-bottom: 0px;
          display: flex;
          flex-direction: column;
          height: 100%;
          cursor: pointer;
          .span{
            color: rgba(172, 69, 0, 1);
            font-size: 28px;
            text-align: center;
          }
          p{
            color: rgba(117, 47, 0, 1);
            font-size: 14px;
            text-align: center;
          }
          button{
            background: linear-gradient(93.02deg, rgba(255,152,37,1) -2.03%,rgba(222,134,143,1) 98.94%);
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            border: 0px;
            border-radius: 35px;
          }
        }
        .fr{
          background-image: url('../../assets/images/fr.png');
          background-size: 100% 100%;
          padding: 30px;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          .span{
            color: rgba(53, 68, 93, 1);
            font-size: 28px;
            text-align: center;
          }
          p{
            color: rgba(48, 55, 68, 1);
            font-size: 14px;
            text-align: center;
          }
          button{
            background: linear-gradient(93.02deg, rgba(255,152,37,1) -2.03%,rgba(222,134,143,1) 98.94%);
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            border: 0px;
            border-radius: 35px;
          }
        }
      }
    }
  }
}
@media (max-width:1536px) and (min-width: 0px){
  .Introduce{
    .container{
      width: auto;
      min-width: 90%;
    }
  }
}