.OrderGoods{
  background-color: #F7F8FA;
  min-height: calc(100vh - 61px);
  display: flex;
  flex-direction: column;
  .HeaderPage{
      background-color: #fff ;
  }
  .container{
    min-width: 1340px;
    height: 95%;
    justify-content: center;
    align-items: flex-start;
    // align-items: center;
    flex: 1;
    .Home{
      display: flex;
      margin-top: 20px;
      height: 96%;
      width: 100%;
      flex-direction: column;
    }
  }
}