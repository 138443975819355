  @charset "utf-8";
  @font-face {
      font-family: 'Muli';
      src: url('../../../../assets/iconfont/ifont.ttf');
      font-weight: 600;
  }
  @font-face {
    font-family: 'Roboto';
    src: url('../../../../assets/fontLibrary/Roboto.woff');
    font-weight: 600;
  }
  .items_label{
    display: flex;
    flex-direction: column;
    font-family:'SourceHanSansSC';
    padding: 8px ;
    font-size: 14px;
    &:hover{
      background-color: rgba(245, 245, 244, 1);
    }
    span{
      margin-top: 8px;
      color: rgba(32, 131, 17, 1);
      font-size: 14px;
      font-family:'Roboto';
    }
  }
  .menu2{
    li{
      padding: 0px 15px;
      .ant-dropdown-menu-title-content{
        display: flex;
        flex-direction: column;
        .menu{
          color: rgba(46, 53, 70, 1);
          font-family: 'Roboto-regular';
          font-size: 20px;
          margin: 10px 0px ;
        }
        button{
          padding: 12px 16px;
          text-align: left;
          height: 100%;
          min-width: 292px;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          background-color: rgba(245, 248, 253, 1);
          border: 0px solid;
          span{
            color: rgba(93, 111, 143, 1);
            font-size: 16px;
            margin-left: 12px;
          }
        }
      }
    }
  }
  .items_label1{
    font-size: 14px;
    display: flex;
    padding: 10px 8px;
    align-items: center;
    font-family:'Roboto-regular';
    span{
      margin-right: 5px;
      font-size: 18px;
    }

  }
  .items_iconfont{
    display: flex;
    flex-direction: column;
    .iconfont{
      margin-right: 10px;
      color: #313639;
    }
    .Current{
      margin-top: 10px;
    }
  }
  .items_label2{
    border: 0px !important;
    font-family: 'Roboto-regular';
    &:hover{
      color: #000 !important;
      background-color: transparent !important;
    }
    img{
      margin-right:2px;
      width: 20px;
    }
  }
  .items_label3{
    font-family: 'Roboto-regular';
    padding: 0px 10px;
  }
  .ant-dropdown-menu-title-content{
    user-select: none;
    span{
      font-family: 'Roboto-regular';
    }
  }
  .ant-dropdown-menu .ant-dropdown-menu-item, .ant-dropdown-menu .ant-dropdown-menu-submenu-title{
    margin:10px 5px 5px!important;
    a{
      font-family: 'Roboto-regular';
    }
  }
  .ant-dropdown-menu-item{
    &:hover{
      background-color: #fff !important;
    }
    img{
      height: 32px;
    }
    .items_label2{
      &:hover{
        background-color: #f5f5f4 !important;
      }
    }
    .items_label1{
      &:hover{
        background-color: #f5f5f4 !important;
      }
    }
  }
  .ant-modal-content{
    border-radius: 4px !important;
  
    .ant-modal-header{
      border-radius: 4px 4px 0 0 !important;
      border: 0px;
      padding:0px;
      margin: -1px 0px 0px;
    }
  }
  .head{
    .head-right{
      .WalletColumn{
        display: none;
      }
      .ant-btn-primary{
        height: 45px;
        border-radius: 10px;
        margin-left: 20px;
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        align-items: center;
        text-shadow: 0 0px 0 rgba(0, 0, 0, 0.12); 
        box-shadow: 0 0px 0 rgba(0, 0, 0, 0.045); 
        span{
          font-family: 'Roboto-regular';
          font-size: 14px;
          display: inline-block;
          font-weight: 800;
        }
        img{
          margin: 0px 8px 0px 0px;
        }
      }

    }

  }
  .ant-modal-footer{
    padding: 10px 30px 40px 30px!important;
    display: flex;
    border-top: 0px solid #f0f0f0 !important;
  .ant-btn-primary{
    background: #1883FB ;
    border: 0px solid #C9E85D !important;
    color: #fff !important;
    border-radius: 5px;
  }
  .ant-btn-primary:hover{
    background-color: #1882fbad ;
    border: 0px solid #208311b0 !important;
    color: #fff !important; 
  }
  .ant-btn-primary:focus{
    background-color: #1882fbad ;
  }
    .ant-btn-default{
      border-radius: 5px;
      color: rgba(125, 130, 145, 1);
      border: 1px solid #BBBBBB  ;
      &:hover{
        color: rgba(125, 130, 145, 1) !important;
        border: 1px solid #BBBBBB   !important;
      }
    }
    .ant-btn-default:hover{
      color: #1883FB ;
      border: 1px solid #1882fbc2;
    }
  }

  .WalletContent_address{
    top: 15px;
    right: 15px;
    position:absolute;
    display: flex;
    justify-content: center;
    color: #000;
    .off{
      background-color: #E4EAFF;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 5px;
      margin-left: 15px;
      .iconfont{
        font-size: 20px;
      }
    }
  }
  .WalletModification{
    padding: 40px 10px 30px;
    
    .WalletContent{
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        font-size: 30px;
        font-family:'Muli' ;
        margin-bottom: 10px;
      }
    }
  // #copy{
  //   display: none;
  // }
    .WalletContent_wallet{
      div{
        display: flex;
        justify-content: space-between;
        background-color: #F7F8FA;
        align-items: center;
        padding: 10px 12px;
        margin: 10px 0px;
        p{
          font-size: 18px;
          margin-top: 0px;
          margin-bottom: 0px;
        }
        a{
          font-size: 18px;
        }
      }
    }
    .SharePaste{
      display: flex;
      justify-content: center;
      h3{
        color: #948280;
        font-size: 20px;
        margin-bottom: 0px;
      }
      p{
        cursor: pointer;
        font-size: 16px;

        margin-bottom: 5px;
        &:nth-of-type(1){
          margin-right: 20px;
        }
        a{
          color: #000;
          font-size: 16px;
        }
      }

    }
  }
  .walletAccount{
    user-select: none;
    border-radius: 20px;
    display: flex;
    margin-left: 20px;
    background-color: #2083111c;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .MobileIcon{
      display: none;
    }
    .ant-progress-inner{
      background-color:#2083111c;
    }
    .walletAccountDiv{
      color: #6191FF;
      font-weight: 600;
    }
  }
  // .ant-modal-body{
  //   padding: 40px 24px 40px !important;
  // }
  .ant-modal-body h5{
    margin: 15px 0px 20px;
    font-size: 22px;
    text-align: center;
    font-family: 'Roboto-regular';
  }
  .ant-avatar.ant-avatar-icon{
    margin-bottom: 10px;
  }
  .ant-btn-round.ant-btn-lg{
    margin-left: 5px;
  }
  .ant-progress-line{
    width: 70% !important;
    min-width: 150px !important;
  }
  .ant-progress{
    display: flex !important;
    .ant-progress-outer{
      width: auto;
      min-width: 200px;
      display: flex;
      align-items: center;
    }
    .ant-progress-text{
      min-width: 50px;
      width: auto;
      line-height: inherit !important;
      display: flex;
      align-items: center;
      margin-right: 8px;
    }
  }
  @media (min-width:0px) and (max-width:660px) {
    .HeaderPage{
      .head {
        .container{
          flex-direction: row;
          justify-content: space-between;
          .head-right{
            .ant-btn-primary{
              display: none;
            }
            .WalletColumn{
              font-size: 24px;
              margin-left: 10px;
              display: inline-block;
              color: #5d6f8f;

            }
            .walletAccount{
              .Mobile_List{
                display: none;
              }
              .MobileIcon{
                display: inline-block ;
              }
            }
          }
        }
      }
    }  

    .Header{
      .ant-btn-primary{
        margin-top: 5px;
      }
    } 
    .ant-progress{
      .ant-progress-outer{
        min-width:100%;
      }
    } 
    .visite{
      font-size: 16px;
      a{
        color: #208311;
        font-size: 16px;
      }
    }

  }
  @media (min-width:660px) {
    .visite{
      display: flex;
      align-items: center;
      font-size: 16px;
      justify-content: center;
      a{
        color: #208311;
        font-size: 16px;
      }
    }
  }
  .iconPark{
    width: 100%;
  }
  .iconPa{
    padding: 40px 24px 12px;
    display: flex;
    font-size: 24px !important;
    align-items: center;
    // border-bottom: 1px solid #f0f0f0;
    font-family: 'Roboto-regular';
    justify-content: center;
    img{
      margin-right: 8px;
    }
    font-size: 16px;
  }
 
  .iconP{
    padding: 16px 20px 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    img{
      margin-right: 8px;
    }
    font-size: 16px;
  }
  .LeftList_Avatar{
    border: 0px !important;
    &:hover{
      color: #389220 !important;
      background-color: #91cd5c46 !important;
    }
  }
  
  .ant-modal-body {
    padding: 24px 30px !important;
    .UploadedLink{
      border: 1px dashed #e5e5e5;
      padding: 12px;
    }
    .RadioCss{
      margin-top: 30px;
      .ant-radio-group{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          display: flex;
          align-items: center;
        }
      }
    }
    p{
      font-size: 16px;
      margin-bottom: 25px;
      font-family: 'Roboto-regular';
      display: flex;
      align-items: center;
      word-break: break-word;
    }
    span{
      font-size: 16px;
      font-family: 'Roboto-regular';
    }
  }
.Avatar{
  user-select: none;
  display: flex;
  align-items: center;
  span{
    margin-right: 10px;
  }
}