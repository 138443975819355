
  @charset "utf-8";
  @font-face {
      font-family: 'Muli';
      src: url('../../assets/iconfont/ifont.ttf');
      font-weight: 600;
  }

  @font-face {
    font-family: 'Roboto-regular';
    src: url('../../assets/fontLibrary/Roboto-regular.woff');
    font-weight: 600;
  }
  @font-face {
    font-family: 'SourceHanSansSC';
    src: url('../../assets/fontLibrary/SourceHanSansSC-regular.woff');
    font-weight: 600;
  }

  // .files-link {
  //   color: #d9d9d9;
  //   border: 1px solid;
  //   padding: 5px 10px;
  //   cursor: auto;
  // }
  
  // .nft-link {
  //   cursor: pointer;
  // }
  .Header{
    text-align: center;
    width: 100%;
    padding: 14px 0px;
    color: #fff;
    font-family: 'Roboto';
    font-size: 18px;
    background-image: url('../../assets/images/pointOut.png');
    .ant-btn-primary{
      background: #fff !important;
      color: #8776FA  !important;
      border: 0px !important;
      margin-left: 20px;
      span{
        font-family: 'Roboto';
      }
    
    }
  }
  .HeaderPage {
    position: relative;
    z-index: 10;
    max-height: 60px;
    width: 100%;
    // background-color: #F7F8FA;
    background-color: transparent;
    .head {
      background: transparent;
    .container {
      min-width: 90%;
      padding: 10px 20px;
      border-radius: 0px 0px 0px 5px;
      justify-content: space-between;
      align-items: center;
      .head-left{
        user-select: none;
        display: flex;
        align-items: center;

        .p{
          margin: 0px 0px 0px 35px;
          cursor: pointer;
          button{
            border: 0px;
            background-color: transparent;
            box-shadow: 0 0 5px transparent;
          }
          .files{
            span{
              color: #1890ff !important;
            }
          }
          .ant-btn-background-ghost.ant-btn-primary{
            color: #000 !important;
          }
          span{
            font-family: 'Roboto';
            font-size: 16px;
            margin: 0px 10px;
          }
        }
        div {
          img{
            cursor: pointer;
            height: 35px;
            &:nth-of-type(2){
              display: none;
            }
          }

      }
      ul{
        display: flex;
        align-items: center;
        li{
          font-size: 18px;
          cursor: pointer;
          margin: 0px 30px;
          color: #86909c;
          &:hover{
            color: #9EA6B0;
          }
        }
        margin: 0px 0px 0px 30px;
      }
      } 
      .head-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .headIconfont{
            cursor: pointer;
            display: flex;
            color: #576A8B;
            align-items: center;
            margin-right: 30px;
            text-align: center;
            font-family: 'Roboto';
          .iconfont{
            margin-right: 10px;
            color: #576A8B;
            font-size: 28px;
          }
        }
        .headIcon{
          display: none;
        }
        .head_collect{
          width: 150px;
          display: flex;
          justify-content: space-between;
          margin: 0px 40px;
          p{
            cursor: pointer;
            margin-bottom: 0px;
            font-size: 30px;
            color: #5D6F8F ;
          }

        }

      }
  }
  }
  }
  @media (max-width:1350px) {
    .HeaderPage {
      .head {
      .container {
        min-width: 100%;
      }
    }
  }
  }
  @media (min-width:0px) and (max-width:800px) {
    .HeaderPage{
      .head{
        .container{
          width: 100% !important;
          .head-left{
            display: flex;
            div{
              img{
                &:nth-of-type(1){
                  display: none;
                }
                &:nth-of-type(2){
                  display: inline-block;
                }
              }
            }
            .p{
              margin: 0px 0px 0px 10px;
              span{
                margin: 0px 10px;
              }
            }
          }
          .head-right{

            .walletAccount{
              margin-left: 10px;
            }
            .head_collect{
              display: none;
            }
            .headIcon{
              display: inline-block;
              color: #576A8B;
              font-family: 'Roboto-regular';
              margin-right: 10px;
            }
            .headIconfont{
              display: none;
            }
          }
        }
      }
      .files{
        display: none;
      }
    }
  }