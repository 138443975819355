.MultiChain{
  button{
    height: 40px;
    color: #5d6f8f;
    background: transparent;
    border: 0px solid #D9D9D9;
    &:hover,&:not(:hover){
      color: #5d6f8f;
      background: transparent;
      border: 1px solid #8A8C8E;
    }
  }
  img{
    height: 24px;
  }
  .ant-space-item{
    display: flex;
    align-items: center;
    font-family: 'Roboto-regular';
  }

}
.ant-dropdown-menu{
  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
    margin: 10px 12px;
  }
}
@media (min-width: 0px) and (max-width: 660px){
  .MultiChain{
    button{
      border: 1px solid #D9D9D9;
      &:hover,&:not(:hover){
        color: #5d6f8f;
        border: 1px solid #8A8C8E;
      }
    }
  }
}