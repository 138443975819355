.Personal {
  background-color: #F7F8FA;
  min-height: 100vh;
  margin-top: -60px;
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;

  .file-input-container {
    position: relative;
    display: inline-block;
    margin-bottom: 24px;
    z-index: 10;
  }
  /*定义动画*/
  @keyframes myfirst {
    0% {
      background-color: transparent;
    }
    100% {
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.5)
    }
  }
    /*定义鼠标移出动画*/
    @keyframes myfirs {
      0% {
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
      }
      100% {
        background-color: transparent;
      }
    }
  .file-input-file {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100%;
    position: absolute;
    z-index: 11;
    border-radius: 50%;
    &:hover {
      // background-color: #00000050;
      animation: myfirst 0.5s forwards;
      .icon-bianji{
        opacity: 1;
      }
    }
    &:not(:hover){
      animation: myfirs 0.5s forwards;
    }
    .icon-bianji{
      color: #fff;
      opacity: 0;
    }
  }



  .file-input-container input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .ant-avatar>img {
    position: relative;
    z-index: 1;
  }

  .file-input-label {
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 80px;
    cursor: pointer;
    position: absolute;
  }

  .HeaderPage {
    background-color: #fff;
  }

  .container {
    min-width: 1340px;
    height: 95%;
    justify-content: center;
    align-items: flex-start;
    // align-items: center;
    flex: 1;

    .Home {
      user-select: none;
      display: flex;
      margin-top: 20px;
      height: 96%;
      width: 90%;
      flex-direction: column;

      .AddButton {
        margin-right: 16px;
      }
      .containerBos{
        font-size: 18px;
        cursor: pointer;
        span{
          font-size: 20px;
          margin-right: 5px;
        }
      }
      .AddButton,
      .SaveButton {
        min-width: 80px;
        min-height: 36px;
      }

      .ant-row {
        align-items: flex-start;
        flex-direction: column;
      }

      .ant-upload.ant-upload-select-picture-card {
        border-radius: 50%;
      }

      .ant-form-horizontal .ant-form-item-control {
        min-width: 500px;
      }

      .Home_Personal {
        background-color: #fff;
        padding: 40px;

        .ant-col-8 {
          flex: none;
        }

        .ant-col-offset-8 {
          margin-left: 0px;
        }

        .anticon {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width:1536px) and (min-width: 660px) {
  .Personal {
    .container {
      min-width: 100%;
      height: 96%;
      padding: 20px 30px;
      width: 100%;
    }
  }
}

@media (max-width: 1350px) {
  .Personal {
    .container {
      .Home {
        .containerBos {
          margin-left: 20px;

        }

        width: 100%;

        .Home_Personal {
          padding: 30px 20px;
        }
      }
    }
  }
}

@media (min-width:0px)and(max-width:660px) {
  .Personal .container {
    min-width: 100%;
    width: 100%;

    .Home {
      .ant-form-horizontal {
        .ant-form-item-control {
          min-width: 100%;
        }
      }
    }
  }
}