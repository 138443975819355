.NftDetail {
  background-color: #F7F8FA;
  min-height: 100vh ;
  margin-top: -60px;
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  .ant-table-wrapper{
    min-height: initial ;
  }
  .container{
    min-width: 1340px;
    height: 95%;
    justify-content: center;
    align-items: flex-start;
    flex: 1 1;
    .Home{
      user-select: none;
      display: flex;
      margin-top: 20px;
      height: 96%;
      width: 90%;
      flex-direction: column;
      .Home_Personal{
        background-color: #fff;
        padding: 25px;
        border: 1px solid rgba(229, 229, 229, 1);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        .item-activity{
          font-size: 16px;
          margin: 40px 0px 16px;
        }
        .NftDetailImg{
          .description{
            p{
              color: rgba(43, 47, 51, 1);
              font-size: 16px;
              margin: 40px 0px 16px;
              display: inline-block;
            }
            span{
              word-wrap: break-word;
              display: block;
              border-radius: 4px;
              border: 1px solid rgba(238, 238, 238, 1);
              padding: 30px;
              font-family: 'Roboto-regular';
            }
          }
          div{
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 0;
          padding-top: 100%;
          border: 1px solid #e5e5e5;
          img{
            padding: 5px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;   
            object-fit: contain;
            border-radius: inherit;   
          }
        }
        }

        .NftFile{
          .custom-class{
            display: block;
            color: rgba(128, 128, 128, 1);
            font-size: 16px;
            margin: 8px 0px 12px;
            font-family: 'Roboto-regular';
          }
          .detail-text{
            font-size: 16px;
            margin-bottom: 16px;
            display: inline-block;
            font-weight: 600;
          }
          .NftFileName{
            word-wrap: break-word;
            margin-top: 10px;
            margin-bottom: 0px;
            font-size: 24px;
            word-spacing: -5px;
            // font-family: 'Roboto-regular';
          }
          .NftFileDetail{
            color: rgba(43, 47, 51, 1);
            font-size: 16px;
            font-family: 'Roboto-regular';
            margin-bottom: 40px;
            display: inline-block;
          }
          .NftFileContract,.NftFileChain{
            padding: 20px;  
            border-radius: 4px;
            font-family: 'Roboto-regular';
          }
          .NftFileContract{
            margin-bottom: 40px;
            background-color: #F8F8F8;
            span{
              font-size: 30px;
              display: inline-block;
              margin-bottom: 24px;
            }
            div{
              span{
                font-size: 16px;
                margin-bottom: 5px;
              }
            }
            p{
              color: rgba(102, 102, 102, 1);
              font-size: 16px;
              line-height: 30px;
              font-family: 'Roboto-regular';
              margin-bottom: 0px;
            }
          }
          .NftFileChain{
            border: 1px solid rgba(229, 229, 229, 1);
            .NftFileDetail{
              font-size: 20px;
              display: inline-block;
              margin-bottom: 24px;
              color: #000;
            }
            p{
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              color: rgba(125, 130, 145, 1);
              span{
                font-family: 'Roboto-regular';
                word-break: break-word;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1350px){
  .NftDetail{
    .container{
      .Home{
        .containerBos{
          margin-left: 20px;
        }
        width: 100%;
        .Home_Personal{
          padding: 30px 20px;
        }
      }
    }
  }  
}
@media (max-width:1536px) and (min-width: 660px) {
  .NftDetail{
    .container{
      min-width: 100%;
        height: 96%;
        padding: 20px 30px;
        width: 100%;
     }
  } 
}
@media (max-width:660px) and (min-width: 0px) {
  .NftDetail{
    .container{
      min-width: 90%;
        height: 96%;
        padding: 20px 5px 0px;
        width: 90%;
        .custom-class{
          margin-top: 20px !important;
        }
     }
  } 
  .HeaderPage .head .container .head-left p span{
    margin: 0px;
  }
}