.NftList {
  background-color: #F7F8FA;
  min-height: 100vh;
  margin-top: -60px;
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  .container{
    min-width: 1340px;
    height: 95%;
    justify-content: center;
    align-items: flex-start;
    flex: 1 1;
    .Home{
      user-select: none;
      display: flex;
      margin-top: 20px;
      height: 96%;
      width: 90%;
      flex-direction: column;
      .iconNft{
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
          font-family: 'Roboto-regular';
        }
        p{
          margin: 0px;
        }
      }
      .Home_Personal{
        background-color: #fff;
        padding: 20px 20px;
        margin: 2em 0px;
        .iconNft{
          display: flex;
          justify-content: flex-end;
        }
        .ant-col{
          .image-wrapper{
            background-color: #f8f9fc;
            border-radius: 4px 4px 0px 0px;
            cursor: pointer;
            position: relative;
            width: 100%;
            height: 0;
            padding-top: 100%;
            img{
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: inherit;  
            }
          }
          .wrapperP{
            cursor: pointer;
            padding: 15px 10px 10px;
            border: 1px solid rgba(238, 238, 238, 1);
            border-top: 0px solid;
            word-break: break-word;
            border-radius: 0px 0px 4px 4px;
            p{
              margin-bottom: 3px;
            }
            .Details{
              font-family: 'Roboto-regular';
              span{
                margin: 8px 0px 0px;
                display: inline-block;
                color: rgba(128, 128, 128, 1);
                font-family: 'Roboto-regular';
                font-size: 12px;
              }
            }
          }
        }
        .empty{
          min-height: 200px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          span{
            font-family: 'Roboto-regular';
          }
        }
      }
    }
  }
}

@media (max-width: 1350px){
  .NftList{
    .container{
      .Home{
        .containerBos{
          margin-left: 20px;
        }
        width: 100%;
        .Home_Personal{
          padding: 30px 20px;
        }
      }
    }
  }  
}
@media (max-width:1536px) and (min-width: 660px) {
  .NftList{
    .container{
      min-width: 100%;
        height: 96%;
        padding: 20px 30px;
        width: 100%;
     }
  } 
}
@media (max-width:660px) and (min-width: 0px) {
  .NftList{
    .container{
      min-width: 90%;
        height: 96%;
        padding: 20px 5px 0px;
        width: 90%;
     }
  } 
  .HeaderPage .head .container .head-left p span{
    margin: 0px;
  }
}