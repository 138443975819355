.RoleList{
  position: relative;
  min-height: 100vh;
  background-color: #FAFAFA;
  margin-top: -60px;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  .container{
    width: 100%;
    padding: 16px 20px;
    align-items: flex-start;
    flex-direction: column;
    .Mobile_content{
      margin-top: 24px;
      width: 100%;
      .Languag{
        padding: 16px;
        background-color: #fff;
        margin-top: 16px;
        color: #5d6f8f;
        span{
          color: #5d6f8f;
          margin-right: 10px;
        }
        div{
          font-family: 'Roboto';
        }
      }
      .Avatar{
        color: rgba(93, 111, 143, 1);
        padding: 16px;
        background-color: #fff;
        flex-direction: column;
        align-items: normal;
        .ant-progress-success-bg, .ant-progress-bg{
          background-color: #AECBFF ;
        }
        .ant-progress-line{
          margin-top: 16px;
          width: 100% !important;
          .ant-progress-inner{
            border-radius: 10px;
            .ant-progress-bg{
              height: 10px !important;
  
            }
          }

        }

      }
      .Avatars{
        cursor: pointer;
        color: rgba(93, 111, 143, 1);
        display: flex;
        margin-top: 16px;
        padding: 16px;
        background-color: #fff;
        align-items: center;
        a{
          color: rgba(93, 111, 143, 1);
          i{
            margin-right: 15px;
          }
        }
        p{
          display: flex;
          align-items: center;
          margin-bottom: 0px;

          span{
            margin-right: 10px;
          }
        }
      }
    }
  }
}