@font-face {
  font-family: "iconfont"; /* Project id 4073143 */
  src: url('iconfont.woff2?t=1701774710579') format('woff2'),
       url('iconfont.woff?t=1701774710579') format('woff'),
       url('iconfont.ttf?t=1701774710579') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chongzhi:before {
  content: "\e6a7";
}

.icon-github:before {
  content: "\e605";
}

.icon-telegram:before {
  content: "\e8db";
}

.icon-shipin:before {
  content: "\e61e";
}

.icon-weizhiwenjian:before {
  content: "\e674";
}

.icon-shangchuantupian:before {
  content: "\e634";
}

.icon-yinle:before {
  content: "\e603";
}

.icon-yasuobao1:before {
  content: "\e606";
}

.icon-z-wenjian:before {
  content: "\e602";
}

.icon-yasuobao:before {
  content: "\e61a";
}

.icon-PDF:before {
  content: "\e608";
}

.icon-a-wenjianiconwenjian:before {
  content: "\e60b";
}

.icon-tixing:before {
  content: "\e68e";
}

.icon-quxiao:before {
  content: "\e622";
}

.icon-twitter:before {
  content: "\e882";
}

.icon-discord:before {
  content: "\e604";
}

.icon-fuzhi:before {
  content: "\e636";
}

.icon-leimutubiaozhubaoshoulian:before {
  content: "\e619";
}

.icon-icon:before {
  content: "\e620";
}

.icon-zuojiantou:before {
  content: "\e60e";
}

.icon-bianji:before {
  content: "\e601";
}

.icon-a-zuojiantouzhixiangzuojiantou:before {
  content: "\e60d";
}

.icon-shijie:before {
  content: "\e612";
}

.icon-tuichu:before {
  content: "\e7ed";
}

.icon-lingdang:before {
  content: "\e600";
}

.icon-kongjianfenxi:before {
  content: "\e648";
}

.icon-dingdan:before {
  content: "\e76d";
}

