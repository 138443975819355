.SharingInterface{
  background-color: #F7F8FA;
  min-height: calc(100vh - 61px);
  display: flex;
  flex-direction: column;
  .container{
    min-width: 1340px;
    height: 95%;
    justify-content: center;
    align-items: flex-start;
    flex: 1 1;
    .Home{
      user-select: none;
      display: flex;
      margin-top: 20px;
      height: 96%;
      width: 90%;
      flex-direction: column;
      .Home_Personal{
        background-color: #fff;
        .SharingTop {
          padding: 40px 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .SharingField{
            color: #9A9A9A ;
            svg{
              margin-right: 5px;
            }
            p{
              margin: 0px  0px 0px 35px;
            }
          }
          p{
            font-family: 'Roboto-regular';
            display: flex;
            align-items: center;
            overflow-wrap: break-word;
          }
          .img{
            font-size: 32px;
            color: rgba(41, 141, 248, 1);
            margin-right: 10px;
          }
          .imgs{
            font-size: 20px;
          }
          span{
            font-family: 'Roboto-regular';
            font-size: 16px;
          }
          button{
            margin: 10px;
            border-radius: 4px;
            height: 36px;
            border: 1px solid rgba(41, 141, 248, 1);
            color: rgba(41, 141, 248, 1);
          }
        }
        .SharingBotton{
          padding: 40px 20px;
          display: flex;
          border-top:1px solid rgba(229, 229, 229, 1);
          justify-content: center;
          img{
            max-width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width:1536px) and (min-width: 660px) {
  .SharingInterface{
    .container{
      min-width: 100%;
        height: 96%;
        padding: 20px 30px;
        width: 100%;
     }
  } 
}
@media (max-width: 1350px){
  .SharingInterface{
    .container{
      .Home{
        .containerBos{
          margin-left: 20px;
        }
        width: 100%;
        .Home_Personal{
          padding: 30px 20px;
        }
      }
    }
  }  
}

@media (max-width:1350px) and (min-width: 660px) {
  .SharingInterface{
    .container{
      min-width: 100%;
        height: 96%;
        padding: 20px 30px;
        width: 100%;
        .Home{
          .Home_Personal{
            .SharingTop{
              flex-wrap: wrap;
            }
            .SharingBotton{
              img{
                width: 100%;
              }
            }
          }
        }
     }
  } 
}
@media (max-width:660px) and (min-width: 0px) {
  .SharingInterface {
    .container{
        min-width: 100%;
        height: 96%;
        padding: 20px 5px 0px;
        width: 100%;
        .Home{
          .Home_Personal{
            .SharingTop{
              flex-wrap: wrap;
              .SharingField{
                flex-wrap: wrap;
                p{
                  margin: 20px 0px 0px 0px;
                }
              }
            }
            .SharingBotton{
              img{
                width: 100%;
              }
            }
          }
        }
     }
  } 
  .HeaderPage .head .container .head-left p span{
    margin: 0px;
  }
}