
.NftCreate {
  background-color: #F7F8FA;
  min-height: 100vh;
  margin-top: -60px;
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 10px;
  }
  #control-ref_Price{
    border-radius: 10px 0px 0px 10px;
  }
  .ant-input-group-addon{
    border-radius: 0px 10px 10px 0px;
  }
  .container{
    min-width: 1340px;
    height: 95%;
    justify-content: center;
    align-items: flex-start;
    flex: 1 1;
    .Home{
      user-select: none;
      display: flex;
      margin-top: 20px;
      height: 96%;
      width: 90%;
      flex-direction: column;
      .NftCreate_Personal{
        background-color: #fff;
        padding: 40px 40px;
        display: flex;
        flex-direction: column;
        .file-input-container {
          position: relative;
          display: block;
          margin-bottom: 24px;
          z-index: 10;
        }
        p{
          word-wrap: break-word;
        }
        .FormButton{
          .ant-btn-primary{
            width: 150px;
            height: 40px;
          }
          .ant-btn-default{
            width: 150px;
            height: 40px;
            margin-left: 20px;
          }
        }
        /*定义动画*/
        @keyframes myfirst {
          0% {
            background-color: transparent;
          }
          100% {
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.5)
          }
        }
          /*定义鼠标移出动画*/
          @keyframes myfirs {
            0% {
              background-color: rgba(0, 0, 0, 0.5);
              box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
            }
            100% {
              background-color: transparent;
            }
          }
        .file-input-file {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 200px;
          width: 200px;
          position: absolute;
          z-index: 11;
          &:hover {
            // background-color: #00000050;
            animation: myfirst 0.5s forwards;
            .icon-bianji{
              opacity: 1;
            }
          }
          &:not(:hover){
            animation: myfirs 0.5s forwards;
          }
          .icon-bianji{
            color: #fff;
            opacity: 0;
          }
        }
        .file-input-container input[type="file"] {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
        span{
          font-size: 16px;
        }
        p{
          font-family: 'Roboto-regular';
          color: rgba(125, 130, 145, 1);
          font-size: 14px;
        }
      }
    }
  }
}
  .ant-modal-close-x{
    display: none !important;
  }

.my-elementLoading{
  display: flex;
  justify-content: center;
  min-height: 300px;
  align-items: center;
}
@media (max-width: 1350px){
  .NftCreate{
    .container{
      .Home{
        .containerBos{
          margin-left: 20px;
        }
        width: 100%;
        .Home_Personal{
          padding: 30px 20px;
        }
      }
    }
  }  
}
@media (max-width:1536px) and (min-width: 660px) {
  .NftCreate{
    .container{
      min-width: 100%;
        height: 96%;
        padding: 20px 30px;
        width: 100%;
     }
  } 
}
@media (max-width:660px) and (min-width: 0px) {
  .PageLoader p{
    margin-top: 20px;
  }
  .elementLoadingDiv{
    padding-bottom: 24px;
  }
  .NftCreate{
    .container{
      min-width: 90%;
        height: 96%;
        padding: 20px 5px 0px;
        width: 90%;
        .FormButton{
          display: flex;
          flex-direction: column;
          .ant-btn-default{
            margin: 10px 0px 0px 0px !important;
          }
        }
     }
  } 
  .HeaderPage .head .container .head-left p span{
    margin: 0px;
  }
}