.UploadPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -65px 0px 0px;
  padding: 60px 0px 0px;
  flex: 1;
  background: linear-gradient(135.26deg, rgba(41,141,248,0.01) -0.2%,rgba(41,141,248,0.2) 27.81%,rgba(41,141,248,0.1) 40.82%,rgba(41,141,248,0.04) 53.83%,rgba(10,203,195,0.2) 70.84%,rgba(41,141,248,0.18) 85.84%,rgba(41,141,248,0.24) 99.85%);
  background-size: 100% 100%;
  min-height: 100vh;
  .container{
    min-width: 90%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    .ant-row{
      justify-content: space-between;
      align-items: center;
    }
    .ant-col{
      user-select: none;
    }
    .UploadCharacters{
      user-select: none;
      padding-bottom: 50px;
      .UploadIcon{
        margin-top: 30px;
        display: flex;
        max-width: 440px;
        justify-content: space-between;
        align-items: center;
        .iconfont{
          cursor: pointer;
          font-size: 50px;
          color: #1883FB;
        }
      }
      h1{
        color: #0C0A1D;
        font-size: 70px;
        line-height: 85px;
        font-family: 'Roboto-Bold';
      }
      p{
        color: rgba(12, 10, 29, 1);
        font-size: 21px;
        font-family: 'Roboto-regular';
        word-spacing: -7px;
      }
      button{
        padding-left:45px ;
        padding-right: 45px;
        height: 60px;
        border-radius: 5px;
        background-color: #1883FB ;
        color: #fff;
        border: 0px;
        max-width: 200px;
        span{
          font-size: 20px;
          font-family: 'Roboto-regular';
        }
      }
    }
    .UploadImg{
      img{
        user-select: none;
        width: 100%;
        border-radius: 5%;
      }
    }
  }
}
@media (min-width: 1536px) and (max-width: 1700px)  {
  .UploadPage {
    .container{
      .UploadCharacters{
        h1{
          color: #0C0A1D;
          font-size: 60px;
          line-height: 80px;
        }
      }
    }
  }
}
@media (min-width: 1280px) and (max-width: 1536px)  {
  .UploadPage {
    .container{
      .UploadCharacters{
        h1{
          color: #0C0A1D;
          font-size: 54px;
          line-height: 74px;
        }
        p{
          font-size: 16px;
        }
        .UploadIcon{
          max-width: 360px;
        }
      }
    }
  }
}
@media (min-width: 1096px) and (max-width: 1280px)  {
  .UploadPage {
    .container{
      .UploadCharacters{
        .UploadIcon{
          max-width: 300px;
        }
        h1{
          color: #0C0A1D;
          font-size: 45px;
          line-height: 65px;
        }
        p{
          font-size: 14px;
        }
        button{
          height: 36px;
          span{
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (min-width: 960px) and (max-width: 1096px){
  .UploadPage {
    .container{
      .UploadCharacters{
        .UploadIcon{
          max-width: 300px;
        }
        h1{
          color: #0C0A1D;
          font-size: 40px;
          line-height: 65px;
        }
        p{
          font-size: 14px;
        }
        button{
          height: 30px;
          span{
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (min-width: 660px) and (max-width: 960px) {
  .UploadPage {
    .container{
      .UploadCharacters{
        .UploadIcon{
          max-width: 250px;
        }
        h1{
          color: #0C0A1D;
          font-size: 30px;
          line-height: 35px;
        }
        p{
          font-size: 10px;
        }
        button{
          height: 30px;
          line-height: 0px;
          padding: 12px 20px;
          span{
            font-size: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 660px) {
  .UploadPage {
    .head{
      .container{
        min-width: 100%;
        padding: 10px 20px;
        width: 100%;
      }
    }
    .container{
      padding-left: 0px;
      .UploadCharacters,.UploadImg{
        .UploadIcon{
          max-width: 85%;
        }
        width: 100%;
        img{
          width: 100%;
        }
        h1{
          color: #0C0A1D;
          font-size: 30px;
          line-height: 40px;
        }
        p{
          font-size: 16px;
        }
        button{
          line-height: 0px;
          padding: 18px 20px;
          span{
            font-size: 14px;
          }
        }

      }
      img{
        width: 100%;
      }
    }
  }
}